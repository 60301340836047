@import url("https://use.typekit.net/hpa8gop.css");
@import-normalize;



@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=a9a61ec3-d1d7-4285-9568-c7a34396bc7c&fontids=1475544,1475550");
@font-face{
    font-family:"Avenir";
    src:url("assets/fonts/1475544/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("assets/fonts/1475544/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff");
}
* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  outline: 0;
  border-style: none;
}

html {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
}

.app {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  font-family: 'Avenir';
  font-weight: 800;
  background: #FFF0D7;
}


@media (max-width: 1024px) {
  .app {
    scroll-snap-points-y: none;
    scroll-snap-type: none;
  }
  
}

.fadedNav {
  opacity: 0;
  transition: opacity 200ms;
}

.nav-enter {
  opacity: 1;
  transition: opacity 200ms;
}

.nav-enter-done{
  opacity: 1;
  transition: opacity 200ms;
} 

.nav-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.nav-exit {
  opacity: 0;
  transition: opacity 200ms;
}
.nav-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.nav-exit-done {
  opacity: 0;
  transition: opacity 200ms;
}
.wallsio-load-more-button {
  margin: 0 auto;
  left: calc(50% - 64px);
  position: relative;
  padding: 10px 25px;
  color:#FFF0D7;
  background-color: #004564;
  border-radius: 3px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: 'Avenir';
  cursor: pointer;
  font-size: 14px;
}

.overlayClass {
  z-index: 30000;
  position: fixed;
  top: 0;
  background:rgba(0,0,0,.6);
  border: 1px solid #FFF0D7;
  width: 100%;
  height: 100vh;
}
